














































































import { BaseUser } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TickIcon from "@/assets/icons/saxcons/tick-circle-broken.svg";
import CancelIcon from "@/assets/icons/saxcons/close-circle-broken.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { LoadingMixin } from "@/mixins/Helpers";
import { RouteWatcherMixin } from "@/mixins/RouteWatcher";
import ProfileIcon from "@/assets/icons/saxcons/profile-circle-linear.svg";
import CalendarIcon from "@/assets/icons/saxcons/calendar-linear.svg";

@Component({
  name: "coach-item-card",
  components: {
    TickIcon,
    CancelIcon,
    ProfileIcon,
    CalendarIcon
  }
})
export default class CoachItemCard extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  RouteWatcherMixin
) {
  @Prop({ required: true })
  coach!: BaseUser;
  @Prop({ required: false })
  isList!: boolean;
  createPrivateLesson = false;
  actionsOpen = false;

  propertiesToWatch = ["createPrivateLesson"];
}
