


























import { fromFirestore } from "@/utils/parser";
import { BaseUser } from "@sportango/backend";
import { USERS_TABLE_NAME } from "@sportango/backend";
import { DB } from "@/firebase";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import {
  collection,
  FieldPath,
  getDocs,
  query,
  where
} from "@firebase/firestore";
import Component, { mixins } from "vue-class-component";
import CardsIcon from "@/assets/icons/saxcons/grid-2-broken.svg";
import ListIcon from "@/assets/icons/saxcons/row-vertical-broken.svg";
import CoachItemCard from "@/components/Coaches/CoachItemCard.vue";
import { SportangoTextField } from "@/components/Inputs/overrides";

@Component({
  name: "coaches-directory",
  components: {
    SportangoTextField,
    CardsIcon,
    ListIcon,
    CoachItemCard
  }
})
export default class CoachesDirectory extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  CurrentUserMixin
) {
  searchString = "";
  get coaches(): Array<BaseUser> {
    let coaches = this.$store.getters.users
      .filter((u) => u.permissions?.hasCoachAccess)
      .filter((u) => !u.disabled)
      .filter((u) =>
        u.displayName?.toLowerCase().includes(this.searchString.toLowerCase())
      );
    // Only show coaches selected by user
    if (
      this.currentUser?.additionalInfo?.coaches &&
      this.currentUser.permissions.hasPlayerAccess
    ) {
      coaches = coaches.filter((c) =>
        this.currentUser?.additionalInfo?.coaches?.includes(c.uid)
      );
    }
    return coaches;
  }

  async mounted() {
    const q = query(
      collection(DB, USERS_TABLE_NAME),
      where(new FieldPath("permissions", "hasCoachAccess"), "==", true)
    );
    const { docs } = await getDocs(q);
    this.$store.commit(
      "users",
      docs.map((d) => fromFirestore<BaseUser>(d, "uid"))
    );
  }
}
