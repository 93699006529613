

































































import AppDialog from "@/components/Navigation/AppDialog.vue";
import { BaseUser } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import DollarIcon from "@/assets/icons/saxcons/dollar-circle-linear.svg";
import CalendarIcon from "@/assets/icons/saxcons/calendar-linear.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { Watch } from "vue-property-decorator";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

@Component({
  name: "coach-profile",
  components: {
    AppDialog,
    DollarIcon,
    CalendarIcon,
    LottieAnimation
  }
})
export default class CoachProfile extends mixins(ResponsiveMixin) {
  get coach(): BaseUser | undefined {
    return this.$store.getters.users.find(
      (u) => u.uid === this.$route.params.id
    );
  }

  get coachProfileInfo(): string {
    if (this.coach?.additionalInfo?.aboutMe) {
      return this.coach.additionalInfo.aboutMe.replaceAll("\n", "<br/>");
    } else {
      return "Wow! Such empty";
    }
  }

  openCalendar() {
    if (this.coach) {
      this.$router.push(`/coaches/calendar/${this.coach.uid}`);
    }
  }

  mounted() {
    this.setDocumentTitle();
  }

  @Watch("coach")
  setDocumentTitle() {
    document.title = `Sportango | Coach - ${this.coach?.displayName}`;
  }
}
